import jwt_decode from 'jwt-decode';
import axios from 'axios';

import apiConfig from '../config/api';
import { clearAuthUserData } from './clearAuthUserData';
import { getAPIToken } from './getAPIToken';
import { configAxios, IOptions } from './configAxios';
import { logout } from './logout';
import { getAPIBroker } from '../app/Pages/Auth/body/utils';
import { getCookie, setCookie } from './cookie';
import { prepareAuthData } from './prepareAuthData';
import { getQueryParam } from './getQueryParam';
import { getXDomainHeader } from './getXDomainHeader';

const REFRESH_TOKEN_BEFORE_EXPIRE_TIME = 300000; // 5 min before expire time

export const preflightAPICall = async(callback: () => unknown, forceUpdate?: boolean) => {
  const now = new Date().getTime();
  const authCookie = getCookie('authentication_result');

  const parsedAuthData = authCookie && JSON.parse(authCookie);
  if (!parsedAuthData) {
    clearAuthUserData();
    window.location.href = '/login?logout=true';
  }

  const decodedToken: Record<string, unknown> = jwt_decode(parsedAuthData.access_token);

  const expiresAt = decodedToken?.exp as number * 1000; // Convert to the milliseconds
  const username = decodedToken?.username as string;
  const refreshToken = parsedAuthData.refresh_token;
  const domain = getXDomainHeader();

  if (!expiresAt || (now - (parsedAuthData.user_time_diff || 0)) >= (expiresAt - REFRESH_TOKEN_BEFORE_EXPIRE_TIME) || forceUpdate) {
    delete axios.defaults.headers.common['X-Broker'];
    delete axios.defaults.headers.common['X-Domain'];

    return await axios.post(`${apiConfig.IDENTIFIER}/token/refresh/${apiConfig.TENANT}`,
      {
        'username': username,
        'refresh_token': refreshToken,
      },
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      }).then(response => {
      let headers = {} as IOptions;
      const data = response.data;
      const broker = getAPIBroker();

      const authResults = {
        ...parsedAuthData,
        access_token: data.authentication_result?.access_token,
      };

      headers['authHeader'] = `${authResults.token_type} ${authResults.access_token}`;

      if (broker) {
        authResults['broker'] = broker;
        headers['broker'] = broker;
      }

      if (domain) {
        headers['domain'] = domain;
      }

      setCookie('authentication_result', JSON.stringify(prepareAuthData(authResults)), 365, window._env_.COOKIE_DOMAIN);

      configAxios(headers);

      return callback();
    }).catch(() => {
      logout();
    });
  } else {
    delete axios.defaults.headers.common['X-Domain'];

    let headers = {} as IOptions;

    if (!axios.defaults.headers.common['Authorization'] || isAuthHeaderInconsistent()) {
      headers['authHeader'] = getAPIToken();
    }

    if (!axios.defaults.headers.common['X-Broker']) {
      headers['broker'] = getAPIBroker();
    }

    if (domain) {
      headers['domain'] = domain;
    }

    configAxios(headers);

    return callback();
  }

};

const isAuthHeaderInconsistent = () => getAPIToken() !== axios.defaults.headers.common['Authorization'];

